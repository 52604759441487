import _ from '@utils/store-helpers';
import UsersDb from '@src/services/firebase/users-db';
import { auth, storage, db } from '@src/services/firebase/init';
import { firestoreAction } from 'vuexfire';
// const deviceSnapshotUnsub = null;
export const state = {
  devicesLoading: true,
  devices: [],
};

export const getters = {};

export const mutations = {};

export const actions = {
  bindUserDevices: firestoreAction(({ bindFirestoreRef, rootState }) => {
    const { uid, isAdmin } = rootState.auth.user;
    let devicesRef = db.collection('devices');

    if (!isAdmin) {
      devicesRef = devicesRef.where('ownerId', '==', uid);
    }

    return bindFirestoreRef('devices', devicesRef, { serialize: _.serialize });
  }),

  unbindUserDevices: firestoreAction(({ unbindFirestoreRef }) => {
    unbindFirestoreRef('devices');
  }),

  // avatar: UintA8Array
  async setUserAvatar({ rootState }, { avatar }) {
    const uid = auth.currentUser.uid;

    var storageRef = storage.ref();

    const fileName = `images/avatars/${auth.currentUser.uid}.png`;

    var imgRef = await storageRef.child(fileName).put(avatar, {
      contentType: 'image/png',
      customMetadata: {
        userId: uid,
      },
    });

    const imageUrl = await imgRef.ref.getDownloadURL();

    return imageUrl;
  },
  async updateProfile({ commit }, { username, location, tzCode }) {
    try {
      const usersDb = new UsersDb();

      await usersDb.update({
        id: auth.currentUser.uid,
        username: username,
        location: location,
        tzCode: tzCode,
      });

      return true;
    } catch (err) {
      return new Error(err);
    }
  },
};
