<template>
  <div class="header py-4">
    <div class="container">
      <b-navbar class="p-0 release-notes" toggleable="md">
        <b-navbar-brand :to="{ name: 'home' }" class="header-brand">
          <img
            alt="BrewStatus logo"
            class="header-brand-img h-8"
            src="@assets/svg/kegbit-logo-color.svg"
          />
        </b-navbar-brand>

        <b-navbar-toggle class="border-0" target="nav_collapse">
          <i class="fal fa-bars"></i>
        </b-navbar-toggle>

        <b-collapse id="nav_collapse" is-nav>
          <b-navbar-nav class="ml-auto mt-4 mt-md-0">
            <b-nav-item
              :to="{ name: 'user_profile' }"
              class="d-block d-md-none"
            >
              <i class="fad fa-fw fa-user mr-2"></i>
              Profile
            </b-nav-item>

            <b-nav-item class="d-block d-md-none" @click.prevent="logOut">
              <i class="fad fa-fw fa-sign-out mr-2"></i> Sign Out
            </b-nav-item>

            <b-nav-item-dropdown
              v-if="user"
              boundary="window"
              class="leading-none d-none d-md-block"
              no-caret
              right
            >
              <template slot="button-content">
                <span
                  :style="{ backgroundImage: `url(${user.avatar})` }"
                  class="avatar avatar-white"
                ></span>
                <span class="ml-2">
                  <span class="text-default">{{ user.username }}</span>
                  <small class="text-muted d-block mt-1">{{
                    user.email
                  }}</small>
                </span>
              </template>

              <b-dropdown-item :to="{ name: 'user_profile' }">
                <i class="dropdown-icon fad fa-user"></i>
                Profile
              </b-dropdown-item>

              <b-dropdown-divider />

              <b-dropdown-item @click.prevent="logOut">
                <i class="dropdown-icon fad fa-sign-out"></i> Sign Out
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { authComputed, authMethods } from '@store/helpers';

export default {
  name: 'NavHeader',
  computed: {
    ...authComputed,
  },
  methods: {
    ...authMethods,
  },
};
</script>

<style>
.releaseNotes #HW_badge_cont:after {
  content: 'Changelog';
  font-size: 0.8em;
  color: #aaa;
  position: absolute;
  left: 30px;
  line-height: 32px;
}
</style>
