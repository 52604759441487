import { db } from './init.js';
import isNil from 'lodash/isNil';

let asyncFirestore = null;

// Lazy load firestore with async import is important for performance
export default () => {
  if (isNil(asyncFirestore)) {
    asyncFirestore = import(
      /* webpackChunkName: "chunk-firestore" */ 'firebase/firestore'
    ).then(() => {
      // firebase.firestore().settings({
      //   host: 'localhost:8089',
      //   ssl: false,
      // });
      // firebase.firestore().enablePersistence({ synchronizeTabs: true });
      return db;
    });
  }
  return asyncFirestore;
};
