import Vue from 'vue';

import VueContentPlaceholders from 'vue-content-placeholders';
import Toasted from 'vue-toasted';
import BootstrapVue from 'bootstrap-vue';

// import VueMoment from 'vue-moment';

// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import appInsights from '@src/plugins/app-insights';

import '@assets/scss/bundle.scss';
import 'nprogress/nprogress.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// import '@simonwep/pickr/dist/themes/classic.min.css';
// import '@simonwep/pickr/dist/themes/monolith.min.css';
import '@simonwep/pickr/dist/themes/nano.min.css';

import router from '@src/router';
import store from '@src/store';
import App from './App.vue';

import { Timestamp } from '@src/services/firebase/init';
import WebFont from 'webfontloader';
import { EventBus } from '@src/plugins/event-bus';
import VueTimeago from 'vue-timeago';
import VueWorker from 'vue-worker';
import { firestorePlugin } from 'vuefire';
import keys from 'lodash/keys';
// Vue.component('ValidationProvider', ValidationProvider);
import './vee-validate';

Vue.prototype.$eventbus = EventBus;

const convertObjectTimestampPropertiesToDate = function(obj) {
  keys(obj)
    .filter((prop) => obj[prop] instanceof Object)
    .forEach((prop) => {
      if (obj[prop] instanceof Timestamp) {
        obj[prop] = obj[prop].toDate().toISOString();
      } else {
        convertObjectTimestampPropertiesToDate(obj[prop]);
      }
    });

  return obj;
};

const serialize = (snapshot) => {
  const data = snapshot.data();
  convertObjectTimestampPropertiesToDate(data);
  return Object.defineProperty(data, 'id', { value: snapshot.id });
};

Vue.use(firestorePlugin, { serialize });
Vue.use(VueWorker);
Vue.use(BootstrapVue);
Vue.use(Toasted, {
  iconPack: 'fontawesome', // set your iconPack, defaults to material. material|fontawesome|custom-class
});

Vue.use(VueContentPlaceholders);
// kVue.use(VueMoment);
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
});
// Vue.use(VueAnalytics, {
//   id: 'UA-143598667-1',
//   router,
//   autoTracking: {
//     screenview: true,
//   },
//   debug: {
//     // enabled: process.env.NODE_ENV === 'development', // default value
//     // trace: false, // default value
//     // sendHitTask: process.env.NODE_ENV === 'production', // default value
//   },
// });

appInsights.loadAppInsights();

// if (process.env.NODE_ENV !== 'development') {
//   Sentry.init({
//     dsn: 'https://c093785faa054a6eaf8531f40c6f48f5@sentry.io/1492226',
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
//     environment:
//       process.env.NODE_ENV !== 'development' ? 'production' : 'development',
//   });
// }

Vue.config.productionTip = false;

const fontFamilies = [
  { name: 'Arial' },
  { name: 'Comfortaa' },
  { name: 'Fira Mono' },
  { name: 'Fira Sans' },
  { name: 'Roboto' },
  { name: 'Sniglet' },
  { name: 'Source Sans Pro' },
  { name: 'Ubuntu' },
  { name: 'Ubuntu Condensed' },
  { name: 'Ubuntu Mono' },
];

WebFont.load({
  google: {
    families: fontFamilies.map((x) => x.name),
  },
  loading: () => {
    // console.log('loading fonts');
    // this.areFontsLoaded = false;
  },
  active: () => {
    // console.log('loaded fonts');
    // this.areFontsLoaded = true;
  },
  fontloading: (familyName, fvd) => {
    // console.log(familyName, fvd);
  },
});

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err, vm, info);
  // if (Vue.appInsights) {
  //   try {
  //     Vue.appInsights.trackException({
  //       exception: new Error(err),
  //       options: {
  //         info: info,
  //       },
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
};

window.onerror = (msg, url, line, col, error) => {
  const errorDetails = { msg, url, line, col, error };
  console.error(errorDetails);
  rootErrors.push(errorDetails);
  // if (Vue.appInsights) {
  //   try {
  //     Vue.appInsights.trackException({
  //       exception: error,
  //       options: errorDetails,
  //     });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }
};

window.onunhandledrejection = (event) => {
  console.warn(`UNHANDLED PROMISE REJECTION:`);
  if (typeof event !== 'undefined') {
    console.warn(event);
  }
};

// window.addEventListener('unhandledrejection', function(event) {
//   console.error(event);
//   rootErrors.push({ event });
//   if (Vue.appInsights) {
//     try {
//       Vue.appInsights.trackException({ exception: new Error(event.reason) });
//     } catch (err) {
//       console.error(err);
//     }
//   }
// });

export const rootErrors = [];

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
