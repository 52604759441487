import GenericDB from './generic-db';
import firestore from './async-firestore';

export default class DeviceMetricsDb extends GenericDB {
  constructor(deviceId) {
    super(`devices/${deviceId}/metrics`);
  }

  async getLatestMetric() {
    const collectionRef = (await firestore()).collection(this.collectionPath);

    const query = collectionRef.orderBy('created', 'desc').limit(1);

    const formatResult = (result) =>
      result.docs.map((ref) =>
        this.convertObjectTimestampPropertiesToDate({
          id: ref.id,

          ...ref.data(),
        })
      );

    return query.get().then(formatResult);
  }
}
