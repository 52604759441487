import axios from 'axios';
import store from '@store';

const API_URL = process.env.VUE_APP_API_URL || 'http://localhost:7071/api/';

const azureAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

azureAxios.interceptors.request.use(
  async (config) => {
    const token = await store.dispatch('auth/getToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default azureAxios;
