import keys from 'lodash/keys';
import { Timestamp } from '@src/services/firebase/init';

const convertObjectTimestampPropertiesToDate = function(obj) {
  keys(obj)
    .filter((prop) => obj[prop] instanceof Object)
    .forEach((prop) => {
      if (obj[prop] instanceof Timestamp) {
        obj[prop] = obj[prop].toDate().toISOString();
      } else {
        convertObjectTimestampPropertiesToDate(obj[prop]);
      }
    });

  return obj;
};

export default {
  set: (key, persist, prefix) => (state, value) => {
    state[key] = value;

    if (prefix) {
      prefix += '.';
    } else {
      prefix = '';
    }

    if (persist) {
      if (value == null) {
        localStorage.removeItem(prefix + key);
      } else {
        localStorage.setItem(prefix + key, JSON.stringify(value));
      }
    }
  },

  get(key, defaultValue = null) {
    const item = localStorage.getItem(key);

    if (item == null || typeof item === 'undefined') {
      return defaultValue;
    }

    return JSON.parse(item);
  },

  serialize(snapshot) {
    const data = snapshot.data();
    convertObjectTimestampPropertiesToDate(data);
    return Object.defineProperty(data, 'id', { value: snapshot.id });
  },
};
