<template>
  <div id="app" class="page">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { authComputed } from '@store/helpers';
// import { EventBus } from '@plugins/event-bus';

export default {
  name: 'KegBit',
  data: function() {
    return {
      initComplete: false,
      registration: undefined,
      refreshing: false,
    };
  },
  computed: { ...authComputed },
  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) {
          return;
        }
        this.refreshing = true;
        window.location.reload();
      });
    }

    this.$eventbus.$on('device:saved', (data) => {
      this.$toasted.success(data, {
        icon: 'save',
        position: 'bottom-right',
        duration: 3000,
      });
    });
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;

      this.$toasted.show('A new version is available', {
        theme: 'toasted-primary',
        position: 'bottom-right',
        duration: null,
        action: {
          text: 'Refresh',
          onClick: (e, toastObject) => {
            if (!this.registration || !this.registration.waiting) {
              return;
            }
            this.registration.waiting.postMessage('skipWaiting');
            toastObject.goAway(0);
          },
        },
      });
    },
  },
};
</script>
