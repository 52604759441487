import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MSG_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

firebase.analytics();
const db = firebaseApp.firestore();
const storage = firebaseApp.storage();

firebase.functions();
db.settings({});

const perf = firebase.performance();

perf.dataCollectionEnabled = true;
perf.instrumentationEnabled = true;

const auth = firebase.auth();

const { Timestamp, GeoPoint } = firebase.firestore;

export { firebaseApp, db, storage, auth, perf, Timestamp, GeoPoint };
