import store from '@src/store';
import isNil from 'lodash/isNil';

export default [
  {
    path: '/',
    name: 'landing',
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "view-landing" */ '@views/Landing')
      ),
    meta: {
      authRequired: false,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({ name: 'home' });
        } else {
          next({ name: 'login' });
        }
      },
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () =>
      lazyLoadView(import(/* webpackChunkName: "view-home" */ '@views/Home')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      lazyLoadView(import(/* webpackChunkName: "view-login" */ '@views/Login')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({
            name: 'home',
          });
        } else {
          next();
        }
      },
    },
  },
  {
    path: '/untappd-callback',
    name: 'untappd-callback',
    // component: () => lazyLoadView(import('@views/UntappdCallback')),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // console.log('untappd-callback');
        const hash = window.location.search.substring(1);
        const params = {};
        hash.split('&').map((hk) => {
          const temp = hk.split('=');
          params[temp[0]] = temp[1];
        });
        return store.dispatch('untappd/linkAccount', params.code).then(() => {
          next({
            name: 'home',
          });
        });
      },
    },
  },
  {
    path: '/check-login',
    name: 'check-login',
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "view-checklogin" */ '@views/CheckLogin')
      ),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () =>
      lazyLoadView(
        import(
          /* webpackChunkName: "view-lostpassword" */ '@views/LostPassword'
        )
      ),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (store.getters['auth/loggedIn']) {
          next({
            name: 'home',
          });
        } else {
          next();
        }
      },
    },
  },
  // Devices
  {
    path: '/claim-me',
    name: 'claim_device',
    component: () =>
      lazyLoadView(
        import(/* webpackChunkName: "view-claimme" */ '@views/LinkKegBit')
      ),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const claimCode = routeTo.query.code || '';
        // console.log(claimCode);
        if (claimCode.length > 0) {
          store
            .dispatch('devices/claimDevice', {
              claimCode: claimCode,
            })
            .then((id) => {
              next({
                name: 'kegbit_edit',
                params: { id: id },
              });
            })
            .catch((err) => {
              console.error('err', err);
            });
        }
        next();
      },
    },
  },
  {
    path: '/tap/:id/edit',
    name: 'kegbit_edit',
    component: () =>
      import(
        /* webpackChunkName: "view-kegbit-edit" */
        '@views/EditKegBitTap'
      ),
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          if (isNil(routeTo.params.id)) {
            return next({ name: 'home' });
          }
          routeTo.params.isDemo = false;
          if (routeTo.params.id.toLowerCase() === 'demo') {
            routeTo.params.isDemo = true;

            routeTo.params.device = {
              id: 'demo',
              backgroundColor: null,
              deviceType: 'DIGITAP27BW',
              imageData: null,
              layout: null,
              name: 'KegBit-DEMO',
            };

            return next();
          } else {
            const device = await store.dispatch('devices/getDataForEdit', {
              deviceId: routeTo.params.id,
            });

            routeTo.params.device = device;

            return next();
          }
        } catch (err) {
          console.error(err);
          next({
            name: '404',
            params: {
              resource: 'Device',
            },
          });
        }
      },
    },
    props: (route) => ({
      device: route.params.device,
      isDemo: route.params.isDemo,
    }),
  },

  {
    path: '/tap/:id/stats',
    name: 'kegbit_stats',
    component: () =>
      lazyLoadView(
        /* webpackChunkName: "view-kegbit-stats" */ import('@views/TapStats')
      ),
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          if (isNil(routeTo.params.id)) {
            return next({ name: 'home' });
          }

          // if (routeTo.params.id.toLowerCase() === 'demo') {
          //   routeTo.params.isDemo = true;

          //   routeTo.params.device = {
          //     id: 'h8KDkk7lfpEx9tV8pFjc',
          //     backgroundColor: null,
          //     deviceType: 'DIGITAP27BW',
          //     imageData: null,
          //     layout: null,
          //     name: 'KegBit-DEMO',
          //   };

          //   return next();
          // } else {

          routeTo.params.device = await store.dispatch('devices/getDevice', {
            deviceId: routeTo.params.id,
          });

          return next();
          // }
        } catch (err) {
          console.error(err);
          next({
            name: '404',
            params: {
              resource: 'Device',
            },
          });
        }
      },
    },
    props: (route) => ({
      device: route.params.device,
      isDemo: route.params.isDemo,
    }),
  },
  {
    path: '/profile',
    name: 'user_profile',
    component: () =>
      lazyLoadView(
        /* webpackChunkName: "view-user-profile" */ import('@views/UserProfile')
      ),
    meta: {
      authRequired: true,
      async beforeResolve(routeTo, routeFrom, next) {
        routeTo.params.userProfile = await store.dispatch(
          'auth/getUserProfile'
        );

        next();
      },
    },
    props: (route) => ({
      userProfile: route.params.userProfile,
    }),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut');
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.meta.authRequired
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: 'home',
              }
            : {
                ...routeFrom,
              }
        );
      },
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => lazyLoadView(import('@views/Terms')),
    meta: {
      authRequired: false,
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
];
// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    // A component to use while the component is loading.
    loading: require('@views/_loading.vue').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout.vue').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  });
  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children);
    },
  });
}
