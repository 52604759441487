import { mapState, mapGetters, mapActions } from 'vuex';

export const authComputed = {
  ...mapState('auth', {
    user: (state) => state.user,
  }),
  // ...mapState('me', {
  //   settings: (state) => state.settings,
  // }),
  ...mapGetters('auth', ['loggedIn']),
};

export const authMethods = mapActions('auth', ['logIn', 'logOut']);
