import _ from '@utils/store-helpers';
// import axiosAzure from '@utils/axios-azure';
// import axiosFireBase from '@utils/axios-firebase';
import { db } from '@src/services/firebase/init';
// import firestore from '@src/services/firebase/async-firestore';
import DevicesDb from '@src/services/firebase/devices-db';
import DeviceLayoutDb from '@src/services/firebase/device-layout-db';
import DeviceMetricsDb from '@src/services/firebase/device-metrics-db';
import isNil from 'lodash/isNil';
import firebase from 'firebase/app';
// import { stringify } from 'flatted/esm';

export const state = {
  devices: [],
  loadingDevices: false,
};

export const getters = {};

export const mutations = {
  setDevices: _.set('devices'),
  setLoadingDevices: _.set('loadingDevices'),
};

export const actions = {
  // eslint-disable-next-line no-empty-pattern
  getDevice({}, { deviceId }) {
    return new DevicesDb().read(deviceId);
  },
  async getDevices({ commit, rootState }) {
    commit('setLoadingDevices', true);

    try {
      const { user } = rootState.auth;

      let devices = [];

      if (typeof user.isAdmin !== 'undefined' && user.isAdmin === true) {
        devices = await new DevicesDb().readAll();
      } else {
        devices = await new DevicesDb().readAll([['ownerId', '==', user.uid]]);
      }

      const results = [];

      // console.log('devices', devices);

      if (devices.length === 0) {
        results.push({ id: 'demo', name: 'Kegbit-Demo' });
      } else {
        for (let index = 0; index < devices.length; index++) {
          const device = devices[index];

          const metric = await new DeviceMetricsDb(device.id).getLatestMetric();

          if (metric != null && metric.length > 0) {
            results.push(
              Object.assign({}, device, { metric: { ...metric[0] } })
            );
          } else {
            results.push(device);
          }
        }
      }

      commit('setDevices', results);
      commit('setLoadingDevices', false);

      return results;
    } catch (error) {
      console.error(error);
      commit('setLoadingDevices', false);
    }
  },

  async claimDevice({ state, rootState }, { claimCode }) {
    const { user } = rootState.auth;

    try {
      const devicesDb = new DevicesDb();

      const devices = await devicesDb.readAll([
        ['claimCode', '==', claimCode.toUpperCase()],
      ]);

      if (devices.length > 0) {
        const device = devices[0];

        if (isNil(device.ownerId)) {
          return await devicesDb.update({
            id: device.id,
            ownerId: user.uid,
            claimDate: new Date(),
          });
        } else if (device.ownerId === user.uid) {
          return device.id;
        } else {
          throw Error('Device already registered');
        }
      }
    } catch (error) {
      throw Error(error);
    }
  },

  async updateDevice({ state, rootState }, { id, name, image, layout }) {
    try {
      const { user } = rootState.auth;

      var storageRef = firebase.storage().ref();
      const fileName = `devices/${id}/${id}`;

      var imgRef = await storageRef.child(fileName + '.png').put(image, {
        contentType: 'image/png',
        customMetadata: {
          deviceId: id,
        },
      });

      const imageUrl = await imgRef.ref.getDownloadURL();

      const userLayout = db.doc(`users/${user.id}/layouts/${id}`);

      userLayout.set({
        layout: layout,
      });

      await new DeviceLayoutDb(id).create(
        {
          layout: layout,
        },
        id
      );

      await new DevicesDb().update({
        id,
        name,
        imageUrl,
      });
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getStatsForTap({}, { deviceId }) {
    try {
      // const statsRef = (await firestore())
      //   .collection(`devices/${deviceId}/metrics`)
      //   .orderBy('created', 'asc')
      //   .limit(10);
      // const stats = await statsRef.get();
      // console.log('docs', stats);
      // return stats.docs.map((x) => {
      //   const {} = x.data();
      // });

      const stats = await new DeviceMetricsDb(deviceId).readAll(null, [
        'created',
        'desc',
      ]);
      // console.log(stats);
      return stats;
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async getDataForEdit({}, { deviceId }) {
    try {
      const device = await new DevicesDb().read(deviceId);
      return device;
    } catch (error) {
      console.error(error);
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async getCurrentLayout({}, { deviceId }) {
    try {
      const device = await new DeviceLayoutDb(deviceId).read(deviceId);
      return device?.layout;
    } catch (error) {
      console.error(error);
    }
  },
};
