import router from '@src/router';
import _ from '@utils/store-helpers';
import { auth, db } from '@src/services/firebase/init';
import UsersDB from '@src/services/firebase/users-db';
import isNil from 'lodash/isNil';
import md5 from 'md5';
import * as Sentry from '@sentry/browser';
import { firestoreAction } from 'vuexfire';

export const state = {
  user: _.get('user'),
};

export const mutations = {
  setUser: _.set('user', true),
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.user;
  },
  userId(state) {
    return state.user.uid;
  },
};

export const actions = {
  bindCurrentUser: firestoreAction(({ bindFirestoreRef, state }) => {
    // console.log('bindCurrentUser');
    const { uid } = state.user;
    const userRef = db.collection('users').doc(uid);

    return bindFirestoreRef('user', userRef, {
      serialize: _.serialize,
    });
  }),

  async autoSignIn({ commit, dispatch }) {
    const fbu = await new UsersDB().read(auth.currentUser.uid);

    const user = isNil(fbu)
      ? await createNewUserFromFirebaseAuthUser(auth.currentUser)
      : fbu;

    if (!user) {
      return Error("Can't find this user!");
    }

    if (user) {
      commit('setUser', user);

      await dispatch('bindCurrentUser');

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          email: user.email,
          username: user.username,
        });
      });

      return user;
    }

    return Error('Unable to Auto Sign In user');
  },

  async getToken() {
    const user = auth.currentUser;

    if (user) {
      const token = await user.getIdToken();
      return token;
    }

    return null;
  },
  // eslint-disable-next-line
  async validate({ commit, state, dispatch }) {
    if (!state.user) {
      return null;
    }
    const user = await dispatch('getCurrentFirebaseUser');
    return user;
  },

  getCurrentFirebaseUser() {
    return new Promise(function(resolve, reject) {
      const unsub = auth.onAuthStateChanged((user) => {
        unsub();
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    });
  },

  async getUserProfile({ state }) {
    const userFromFirebase = await new UsersDB().read(state.user.uid);
    return userFromFirebase;
  },

  async logIn({ commit, dispatch }, firebaseAuthUser) {
    const userFromFirebase = await new UsersDB().read(firebaseAuthUser.uid);

    const user = isNil(userFromFirebase)
      ? await createNewUserFromFirebaseAuthUser(firebaseAuthUser)
      : userFromFirebase;

    if (isNil(user.username)) {
      user.username = user.email;
    }

    commit('setUser', user);

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        email: user.email,
        username: user.username,
      });
    });

    db.collection('users')
      .doc(user.id)
      .onSnapshot(function(doc) {
        commit('setUser', doc.data());
      });

    return router.push({ name: 'home' });
  },

  // Logs out the current user.
  async logOut({ commit }) {
    try {
      commit('setUser', null);
      await auth.signOut();
      router.push({ name: 'login' });
    } catch (e) {
      commit('setUser', null);
      router.push({ name: 'login' });
    }
  },

  async refreshUser({ commit }, data) {
    commit('setUser', data);
  },
};

// ===
// Private helpers
// ===
const createNewUserFromFirebaseAuthUser = async (firebaseAuthUser) => {
  const providerData = firebaseAuthUser.providerData[0];
  const { displayName, photoURL, email } = providerData;
  const userDb = new UsersDB();

  let username = email;

  let avatar = `//www.gravatar.com/avatar/${md5(
    email.trim().toLowerCase()
  )}?s=100&d=mm&r=g`;

  if (photoURL) {
    avatar = photoURL;
  }

  if (displayName) {
    username = displayName;
  }

  const user = {
    username: username,
    avatar: avatar,
    email,
    uid: firebaseAuthUser.uid,
  };
  // console.log(user);
  const createdUser = await userDb.create(user, firebaseAuthUser.uid);

  return createdUser;
};
