import _ from '@utils/store-helpers';
import axios from 'axios';
import axiosAzure from '@utils/axios-azure';
import UsersDB from '@src/services/firebase/users-db';

export const state = {
  userToken: _.get('untappd.userToken'),
  userInfo: _.get('untappd.userInfo'),
};

export const getters = {
  isUntappdLinked(state) {
    return !!state.userToken;
  },
};

export const mutations = {
  setUserToken: _.set('userToken', true, 'untappd'),
  setUserInfo: _.set('userInfo', true, 'untappd'),
};

export const actions = {
  async linkAccount({ state, commit, rootState }, code) {
    try {
      // console.log('linkAccount');
      const atResponse = await axiosAzure
        .post(`/untappd/get-token`, {
          redirectUrl: process.env.VUE_APP_UNTAPPD_CALLBACK,
          code: code,
        })
        .catch((err) => console.error(err));

      // eslint-disable-next-line
      const access_token = atResponse.data.access_token;

      const utr = await axios.get('https://api.untappd.com/v4/user/info', {
        params: { access_token: access_token },
      });

      const user = utr.data.response.user;

      const updatedUser = {
        id: rootState.auth.user.uid,
        untappd: {
          access_token: access_token,
          account_type: user.account_type,
          is_supporter: user.is_supporter,
          location: user.location,
          uid: user.uid,
          user_avatar: user.user_avatar,
          user_avatar_hd: user.user_avatar_hd,
          user_cover_photo: user.user_cover_photo,
          user_cover_photo_offset: user.user_cover_photo_offset,
          user_name: user.user_name,
          untappd_url: user.untappd_url,
          ...user.stats,
        },
      };

      const userDb = new UsersDB();

      await userDb.update(updatedUser);
    } catch (error) {
      console.error(error);
    }
  },
  async unlinkAccount({ commit, rootState }) {
    const updatedUser = {
      id: rootState.auth.user.uid,
      untappd: null,
    };

    await new UsersDB().update(updatedUser);
  },
  async getBeerInfo({ rootState }, { beerId }) {
    let params = {};

    if (rootState.auth.user.untappd) {
      params = Object.assign(params, {
        access_token: rootState.auth.user.untappd.access_token,
      });
    } else {
      params = Object.assign(params, {
        client_id: process.env.VUE_APP_UNTAPPD_CLIENTID,
        client_secret: process.env.VUE_APP_UNTAPPD_SECRET,
      });
    }

    return axios
      .get(`https://api.untappd.com/v4/beer/info/${beerId}`, {
        params: params,
      })
      .then((response) => {
        // console.log('response', response);
        return response.data.response.beer;
      });
  },
  untappdSearch({ rootState }, { search }) {
    let params = { q: search };

    if (rootState.auth.user.untappd) {
      params = Object.assign(params, {
        access_token: rootState.auth.user.untappd.access_token,
      });
    } else {
      params = Object.assign(params, {
        client_id: process.env.VUE_APP_UNTAPPD_CLIENTID,
        client_secret: process.env.VUE_APP_UNTAPPD_SECRET,
      });
    }

    return axios
      .get('https://api.untappd.com/v4/search/beer', {
        params: params,
      })
      .then((response) => {
        const mappedItems = [];
        // console.log(response);
        response.data.response.homebrew.items.forEach((item) => {
          mappedItems.push({
            beer_id: item.beer.bid,
            beer_label: item.beer.beer_label,
            beer_name: item.beer.beer_name,
            beer_style: item.beer.beer_style,
            beer_abv: item.beer.beer_abv,
            beer_ibu: item.beer.beer_ibu,
            brewery_name: item.brewery.brewery_name,
            brewery_label: item.brewery.brewery_label,
          });
        });

        response.data.response.beers.items.forEach((item) => {
          mappedItems.push({
            beer_id: item.beer.bid,
            beer_label: item.beer.beer_label,
            beer_name: item.beer.beer_name,
            beer_style: item.beer.beer_style,
            beer_abv: item.beer.beer_abv,
            beer_ibu: item.beer.beer_ibu,
            brewery_name: item.brewery.brewery_name,
            brewery_label: item.brewery.brewery_label,
          });
        });

        return mappedItems;
      })
      .catch((err) => {
        console.error(err);
      });
  },

  async getLogo({ state }, { url, width = 128, height = 128, format = 'gif' }) {
    try {
      const img = await axiosAzure
        .get(`/get-image`, {
          params: {
            url: url,
            width: width,
            height: height,
            format: format,
          },
        })
        .catch((err) => console.error(err));

      return img.data;
    } catch (error) {
      console.error(error);
    }
  },
};
