<template>
  <div :class="[showHeader ? 'page-main' : 'page-single']">
    <nav-bar v-if="showHeader"></nav-bar>
    <slot />
  </div>
</template>

<script>
import NavBar from '@components/NavHeader';

export default {
  name: 'MainLayout',
  components: { NavBar },
  props: { showHeader: { type: Boolean, default: true } },
};
</script>
